<template>
  <div :class="$style.home">
    <div :class="$style.haeder">
      <div
        v-for="item in head"
        :key="item.id"
        :class="[active === item.id ? $style.active : null, $style.item]"
        @click="onChoose(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div :class="$style.box">
      <a-row :gutter="[20, 20]">
        <a-col
          v-for="item in data"
          :key="item.id"
          :sm="{ span: 12 }"
          :md="{ span: 12 }"
          :lg="{ span: 12 }"
          :xl="{ span: 8 }"
          :xxl="{ span: 6 }"
        >
          <Item :data="item" @add="onAdd(item)" />
        </a-col>
      </a-row>
    </div>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { centerStoreService } from '@/service'

import Item from './item.vue'

const service = new centerStoreService()

export default {
  name: 'Home',
  components: {
    Item,
  },
  data() {
    return {
      data: [],
      head: [
        {
          name: '全部应用',
          id: 0,
        },
        {
          name: '有效期',
          id: 1,
        },
        {
          name: '已过期',
          id: 2,
        },
      ],
      active: 0,
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onAdd() {
      this.visible = true
    },
    async getList() {
      try {
        const { data } = await service.userList({
          status: this.active,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    onChoose(type) {
      this.active = type
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .haeder {
    display: flex;
    margin-bottom: 20px;

    .item {
      font-size: 16px;
      margin-right: 30px;
      cursor: pointer;
    }

    .active {
      color: @primary-color !important;
    }
  }
}
</style>
